export const mainTheme = {
  palette: {
    black: "#000000",
    primaryTextGreen: "#33FF33",
    secondaryTextGreen: "#66FF66",
    headerGreen: "#009900",
    accentColor: "#00CC00"
  },
  typography: {
    primaryText: {
      fontFamily: "'Fira Code', monospace",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "40px",
      lineHeight: "48px",
    },
    secondaryText: {
      fontFamily: "'Fira Code', monospace",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "25px",
      lineHeight: "25px",
    },
  },
};
